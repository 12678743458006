import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    if (!analytics || !window.currentUser || window.currentUser === undefined)
      return;
    analytics.identify(window.currentUser.id, {
      email: window.currentUser.email,
      username: window.currentUser.username,
    });
  }

  track(event) {
    if (!analytics) return;
    const { target } = event;
    analytics.track('Element Clicked', {
      type: target.tagName.toLowerCase(),
      name: target.dataset.analyticsName,
      text: target.textContent,
    });
  }
}
